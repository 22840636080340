import { ReportData, Content } from '..';
export class ReportDataVM implements ReportData {
  entityId: number;
  category: string;
  telephoneNote: string;
  entityType: string;
  callLog: {
    id: number;
    type: string;
    subtype: string;
    idVStatus: string;
    successfulContact: boolean;
    customerId: number;
    linkedAccounts: Array<number>;
    additionalFields: {
      [key: string]: any;
    };
    content: Array<Content>;
    callSummary: string;
    callSummaryEdited: string;
    callClosure: string;
    callId: string;
    phoneNumber: string;
  };
  scopeId: number;
  subEntityId: number;
  constructor(data?: ReportData) {
    this.setValuesByDefault();

    if (data) {
      Object.assign(this, data);
    }
  }

  private setValuesByDefault(): void {
    this.entityId = 0;
    this.category = '';
    this.telephoneNote = '';
    this.entityType = '';
    this.callLog = {
      id: -1,
      type: '',
      subtype: '',
      idVStatus: '',
      successfulContact: false,
      customerId: 0,
      linkedAccounts: [],
      additionalFields: {
        Vulnerability: '',
        RelationShip: '',
        LinkedAccounts: [],
      },
      content: [],
      callSummary: '',
      callSummaryEdited: '',
      callClosure: '',
      callId: '',
      phoneNumber: '',
    };
    this.scopeId = -1;
    this.subEntityId = 0;
  }
}
