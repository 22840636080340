<div class="genesys-call-log-container">
  <div class="genesys-call-log__content">
    <div class="genesys-call-log__search">
      <div class="genesys-call-log__search--left">
        <horizon-icon
          iconName="phone"
          [iconColor]="globalSettings.colors.mainColor"
          [size]="'15'"
        ></horizon-icon>
        <span
          >{{
            "call_log.call_log_search_view.labels.number_calling" | translate
          }}
          :</span
        >
        <span
          class="genesys-call-log__number"
          [ngClass]="isKnowCaller && 'genesys-call-log__number--known'"
          >{{ callerPhone }}</span
        >
      </div>
      <div class="genesys-call-log__search--right" *ngIf="isKnowCaller">
        <horizon-icon
          class="genesys-call-log__icon"
          iconName="checked"
          iconGroup="checkmark"
          [size]="'15'"
          [iconColor]="globalSettings.colors.successColor"
        ></horizon-icon>
        <span>{{
          "call_log.call_log_search_view.labels.registered" | translate
        }}</span>
      </div>
      <div class="genesys-call-log__search--right" *ngIf="!isKnowCaller">
        <horizon-icon
          class="genesys-call-log__icon"
          iconName="cancel"
          iconGroup="actions"
          [size]="'15'"
          [iconColor]="globalSettings.colors.errorColor"
        ></horizon-icon>
        <span>{{
          "call_log.call_log_search_view.labels.unregistered" | translate
        }}</span>
      </div>
      <div class="genesys-call-log__search--bottom">
        <horizon-generic-grid
          [gridData]="customerData"
          [gridColumns]="customerTemplate"
          [gridConfigSettings]="callLogGridConfigService.genericGridSettings"
          [preselectFirstRow]="preselectFirstRow"
          [applyCellCustomization]="false"
        ></horizon-generic-grid>
      </div>
    </div>
    <div class="genesys-call-log__form">
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
        <div class="genesys-call-log__form-group">
          <label for="name">{{
            "call_log.call_log_search_view.labels.search_name" | translate
          }}</label>
          <horizon-text-input
            [control]="searchForm.controls['name']"
            (keydown.enter)="onSubmit()"
          ></horizon-text-input>
        </div>
        <div class="genesys-call-log__form-group">
          <label for="account">{{
            "call_log.call_log_search_view.labels.account_number" | translate
          }}</label>
          <horizon-number-input
            [format]="'###'"
            [showSpinButton]="false"
            [control]="searchForm.controls['accountNumber']"
            (keydown.enter)="onSubmit()"
          ></horizon-number-input>
        </div>
        <horizon-button
          [isProgressButton]="false"
          class="genesys-call-log__search-button"
          [text]="'call_log.call_log_search_view.labels.search' | translate"
          [type]="'info'"
          [buttonColor]="globalSettings.colors.mainColor"
          (clickEvent)="onSubmit()"
        ></horizon-button>
      </form>
    </div>
  </div>
  <div class="genesys-call-log__content">
    <div class="genesys-call-log__grid-header">
      <span>{{
        "call_log.call_log_search_view.labels.results" | translate
      }}</span>
      <div class="genesys-call-log__separator"></div>
    </div>
    <div class="genesys-call-log__grid">
      <horizon-generic-grid
        [gridConfigSettings]="callLogGridConfigService.gridSettings"
        [gridColumns]="resultsTemplate"
        [applyCellCustomization]="false"
        [gridData]="resultsData"
        (rowSelectedEvent)="onRowActionEvent($event)"
      ></horizon-generic-grid>
      <horizon-button
        [text]="'Call Log'"
        [type]="'info'"
        [buttonColor]="globalSettings.colors.mainColor"
        [isDisabled]="!isCallerSelected"
        [isProgressButton]="isSearching"
        (clickEvent)="openCallLog()"
      ></horizon-button>
    </div>
  </div>
  <div class="genesys-call-log__content" *ngIf="isCallerSelected">
    <div class="genesys-call-log__grid-header">
      <span
        >{{
          "call_log.call_log_search_view.labels.recent_calls"
            | translate : { customerDisplayName: customerDisplayName }
        }}
      </span>
      <div class="genesys-call-log__separator"></div>
    </div>
    <horizon-generic-grid
      [gridData]="recentCallsData"
      [gridColumns]="recentCallsTemplate"
      [gridConfigSettings]="callLogGridConfigService.genericGridSettings"
      [sortSettings]="recentCallsDataSortSettings"
      [applyCellCustomization]="false"
    ></horizon-generic-grid>
  </div>
</div>
