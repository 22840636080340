import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ItemContext, UtilsService } from '@horizon/core';
import { CallLogGridConfigService } from '../../config/call-log-grid-config.service';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';
import { globalSettings } from 'src/global-settings';
import {
  OpenCallLogReportEmitter,
  TableData,
  TableTemplate,
} from '../../models';
import { filter, Subject, takeUntil } from 'rxjs';
import { layout } from '../../config/layout';
import { DashboardApiService } from '@shared/services/dashboard-api.service';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { NotificationService } from '@horizon/core-notifications';

@Component({
  selector: 'call-log-search-view',
  templateUrl: './call-log-search-view.component.html',
  styleUrls: ['./call-log-search-view.component.scss'],
})
export class CallSearchViewComponent implements OnInit, OnDestroy {
  @Input() callerPhone: string;
  @Input() isSearching: boolean = false;
  @Output() public openCallLogReport: EventEmitter<OpenCallLogReportEmitter> =
    new EventEmitter<OpenCallLogReportEmitter>();

  public accountId: number;
  public customerId: number;
  public customerDisplayName: string;
  public globalSettings: any = globalSettings;
  public isCallerSelected: boolean = false;
  public isKnowCaller: boolean = false;
  public searchForm: FormGroup;
  public preselectFirstRow: boolean = false;

  //#region STATIC GRID SELECTED CUSTOMER VARIABLESl
  public customerData: Array<TableData>;
  public customerTemplate = this.utils.getLayoutElementById(
    layout,
    'customer-call-log-search-list-columns'
  ).elements;
  //#endregion

  //#region DYNAMIC GRID RESULTS VARIABLES
  public resultsData: Array<any> = [];
  public resultsTemplate = this.utils.getLayoutElementById(
    layout,
    'results-call-log-search-list-columns'
  ).elements;
  //#endregion

  //#region STATIC GRID RECENT CALLS VARIABLES
  public recentCallsData: Array<TableData>;
  public recentCallsDataSortSettings: any;
  public recentCallsTemplate: TableTemplate = this.utils.getLayoutElementById(
    layout,
    'recent-calls-call-log-search-list-columns'
  ).elements;
  //#endregion

  //#region DYNAMIC FORM VARIABLES
  public context: ItemContext;
  public isCreatingMode: boolean = false;
  public scopeId: number = -1;
  //#endregion

  private readonly destroy$ = new Subject<void>();
  private relationship: string;

  constructor(
    public callLogGridConfigService: CallLogGridConfigService,
    private readonly apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private readonly callLogService: HorizonGenesysCallLogService,
    private readonly dashboardApiService: DashboardApiService,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly utils: UtilsService
  ) {
    this.searchForm = this.fb.group({
      name: [''],
      accountNumber: [''],
    });
  }

  ngOnInit(): void {
    this.callerPhone =
      this.callLogService.getLocalStorageData('GenesysData').phoneNumber;
    this.searchByTelephoneNumber();
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  /**
   * The function `onRowActionEvent` sets customer data based on a row action
   * event, makes an API call to get call log report, and handles success and error
   * responses accordingly.
   *
   * @param any e The `e` parameter in the `onRowActionEvent` method likely
   * represents an event object that is being passed when a row action event is
   * triggered. This event object may contain information about the action that was
   * performed, such as the data associated with the row that was clicked.
   */
  public onRowActionEvent(e: any): void {
    this.customerData = [e.rowData];
    this.customerId = e.rowData.PartyId;
    this.accountId = e.rowData.AccountId;
    this.customerDisplayName = e.rowData.CustomerName;
    this.relationship = e.rowData.Relationship;

    this.dashboardApiService
      .getCallLogReport(this.customerId, this.accountId)
      .subscribe({
        next: (resp: any) => {
          this.prepareDataForResults(resp);
          this.notificationService.showLoadingSpinner();
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Dashboard'
          );
          this.notificationService.stopLoadingSpinner();
        },
        complete: () => {
          this.isCallerSelected = true;
          this.notificationService.stopLoadingSpinner();
        },
      });
  }

  /**
   * The onSubmit function in TypeScript asynchronously calls a log service to
   * search for results based on form inputs and handles the response accordingly.
   */
  public async onSubmit(): Promise<void> {
    await this.callLogService
      .searchResults(
        undefined,
        this.searchForm.controls.name.value,
        this.searchForm.controls.accountNumber.value
      )
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response) => {
          this.resultsData = response;
          this.notificationService.showLoadingSpinner();
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
          this.notificationService.stopLoadingSpinner();
        },
        complete: () => {
          this.notificationService.stopLoadingSpinner();
        },
      });
  }

  /**
   * The function `openCallLog` clears modal data, emits an event to open a call
   * log report, fetches call log data, handles errors, and opens a new window to
   * display relationships dashboard.
   */
  public async openCallLog(): Promise<void> {
    this.openCallLogReport.emit({
      isCallLogReportView: true,
      accountId: this.accountId,
      customerId: this.customerId,
      callType: this.callLogService.getLocalStorageData('GenesysData').callType,
      callSubtype: this.callLogService.getLocalStorageData('GenesysData')
        .callSubType
        ? this.callLogService.getLocalStorageData('GenesysData').callSubType
        : null,
      relationship: this.relationship,
    });
  }

  /**
   * The function `prepareDataForResults` processes data from a response object and
   * transforms it into a specific format for recent calls data.
   * @param {any} resp - The `resp` parameter in the `prepareDataForResults`
   * function is expected to be an object containing a `data` property. The `data`
   * property should also be an object with a `rows` property, which is an array of
   * objects. Each object in the `rows` array represents
   */
  private prepareDataForResults(resp: any): void {
    this.recentCallsData = [];
    if (resp.data && resp.data.rows.length >= 1) {
      this.recentCallsData = resp.data.rows.map((row: any) => {
        const transformedRow: any = {};
        Object.keys(row).forEach((key) => {
          transformedRow[key] = row[key].value;
        });
        return transformedRow;
      });
    }
  }

  /**
   * The function `searchByTelephoneNumber` asynchronously searches for call log
   * data based on a telephone number and handles the response accordingly.
   */
  private async searchByTelephoneNumber(): Promise<void> {
    const transformedCallerPhone = this.callerPhone.replace('+', '%2b');
    this.callLogService
      .searchByTelephoneNumber(transformedCallerPhone)
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response) => {
          this.resultsData = response;
          if (response.length > 0) this.isKnowCaller = true;
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
        },
        complete: () => {
          if (this.resultsData.length === 1) {
            this.preselectFirstRow = true;
          }
        },
      });
  }
}
