<ejs-dialog
  id="horizon-genesys-substitute-modal"
  #Dialog
  isModal="true"
  width="60%"
  [visible]="true"
  [allowDragging]="true"
  [header]="'call_log.call_log_temporal_substitute_view.title' | translate"
  [showCloseIcon]="true"
  (open)="onOpen($event)"
  (beforeClose)="onBeforeClose($event)"
>
  <div class="genesys-call-log-container">
    <form class="genesys-call-log-container__form">
      <div>
        <label>
          <horizon-icon
            [iconGroup]="'tooltip'"
            [iconName]="'warning'"
            [title]="
              'call_log.call_log_temporal_substitute_view.labels.call_id_tooltip'
            "
            [color]="globalSettings.colors.warningColor"
            [size]="'16'"
          ></horizon-icon>
          {{
            "call_log.call_log_temporal_substitute_view.labels.call_id"
              | translate
          }}
        </label>
        <horizon-text-input
          [control]="form.controls['callId']"
          (valueChange)="onCallIdChange($event)"
        ></horizon-text-input>
      </div>
      <horizon-text-input
        [control]="form.controls['phoneNumber']"
        [label]="
          'call_log.call_log_temporal_substitute_view.labels.phone_number'
        "
        (valueChange)="onPhoneNumberChange($event)"
      ></horizon-text-input>

      <horizon-dropdown
        [control]="form.controls['callType']"
        [label]="'call_log.call_log_temporal_substitute_view.labels.call_type'"
        [dropdownValues]="callTypeOptions"
        [fields]="callTypeOptionsValues"
        [showClearButton]="false"
        (valueSelected)="onCallTypeChange($event)"
      ></horizon-dropdown>

      <horizon-dropdown
        [control]="form.controls['callSubType']"
        [label]="
          'call_log.call_log_temporal_substitute_view.labels.call_sub_type'
        "
        [dropdownValues]="callSubTypeOptions"
        [fields]="callSubTypeOptionsValues"
        [enabled]="isSubTypeCodeEnabled"
        [showClearButton]="false"
        (valueSelected)="onCallSubTypeChange($event)"
      ></horizon-dropdown>

      <horizon-button
        class="genesys-call-log-container__search-button"
        [text]="'call_log.call_log_temporal_substitute_view.labels.search'"
        [type]="'info'"
        [buttonColor]="globalSettings.colors.mainColor"
        [isDisabled]="this.form.invalid"
        (clickEvent)="searchByTelephoneNumber()"
      ></horizon-button>
    </form>
  </div>
</ejs-dialog>
