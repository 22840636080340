export enum AppSections {
  ACCOUNT_CHANGE = 'Account_Change',
  REPAYMENT_STRATEGY = 'Repayment_Strategy',
  FLEXIBLE_FEATURE = 'Flexible_Feature',
  PAYMENT_HOLIDAY = 'Payment_Holiday',
  ACTIVITIES = 'Activities',
  ADD_GUARANTOR_OTHER_SEC = 'Add_Guarantor_Other_Sec',
  ARRANGEMENT_TO_PAY = 'Arrangement_To_Pay',
  COMPLAINTS = 'Complaints',
  COMPLAINTS_ADVANCED = 'Complaints_Advanced',
  COMPLAINTS_FOS_INPUT = 'Complaints_FOS_Input',
  COVENANTS = 'Covenants',
  CURRENT_EXPOSURE = 'Current_Exposure',
  CUSTOMER_CENTRIC = 'Customer_Centric',
  CUSTOMER_CENTRIC_DOCUMENTS = 'Customer_Centric_Documents',
  CUSTOMER_CENTRIC_NOTES = 'Customer_Centric_Notes',
  CUSTOMER_CENTRIC_RELATIONSHIPS = 'Customer_Centric_Relationships',
  CUSTOMER_CENTRIC_DASHBOARD = 'Customer_Centric_Relationships',
  CUSTOMER_CENTRIC_VULNERABILITY = 'Vulnerability',
  CUSTOMER_CENTRIC_KYC = 'Entity_KYC',
  TRANSACTION = 'Transaction_Bulk_Upload_Screen',
  TRANSACTION_IMPORT_FILES = 'Import_Transaction_Files',
  CUSTOMERS = 'Customers',
  CUSTOMERS_KYC = 'Customers_KYC',
  CUSTOMERS_GDPR = 'Customers_GDPR',
  CUSTOMERS_INSOLVENCY = 'Customers_Insolvency',
  CUSTOMERS_FINANCIAL_CRIME = 'Customers_Financial_Crime',
  CUSTOMERS_OVERVIEW = 'Customers_Overview',
  CUSTOMERS_PARTICIPANTS = 'Customers_Participants',
  CUSTOMERS_RELATED_PARTIES = 'Customers_Related_Parties',
  CUSTOMERS_VULNERABILITY = 'Customers_Vulnerability',
  DD_MANAGEMENT = 'Dd_Management',
  LITIGATION = 'Litigation',
  NON_STANDARD_COSTS = 'Non_Standard_Costs',
  RECEIVERSHIP = 'Receivership',
  DOCUMENTS = 'Notes_Documents',
  CALLS = 'Notes_Calls',
  SUSPICIOUS = 'Notes_Suspicious',
  ENTITIES = 'Entities',
  ENTITIES_OVERVIEW = 'Entities_Overview',
  EXECUTE_FULL_REDEMPTIONS = 'Execute_Redemption_Quote',
  EXECUTE_OVERPAYMENT = 'Execute_Redemption_Quote',
  FULL_REDEMPTIONS = 'Redemption_Screen_Access',
  OVERPAYMENT = 'Redemption_Screen_Access',
  GROUP_CONFIG = 'Group_Config',
  GROUP_EXPOSURE = 'GroupExposure',
  GROUPEXPOSURE_COVENANTS = 'GroupExposure_Covenants',
  GROUPEXPOSURE_KYC = 'GroupExposure_KYC',
  INCOME_EXPENDITURE = 'Income_Expenditure',
  INDIVIDUALCUSTOMERS = 'IndividualCustomers',
  PARTIAL_REDEMPTIONS = 'Redemption_Screen_Access',
  ACCOUNT_INFORMATION = 'Account_Information',
  PRODUCT_SWITCH = 'Product_Switch',
  READ_FULL_REDEMPTIONS = 'Read_Redemption_Quote',
  RELATEDPARTIES = 'RelatedParties',
  REMINDERS_ASSIGN = 'Reminders_Assign',
  REPLICATE_FULL_REDEMPTIONS = 'Replicate_Redemption_Quote',
  REPLICATE_OVERPAYMENT = 'Replicate_Redemption_Quote',
  RESTRUCTURE = 'Restructure',
  GOVERNMENT_SCHEME = 'Government_Scheme',
  ROLES_AND_PERMISSIONS = 'Roles_Permissions',
  SCOPES_GROUPS = 'Scopes_Groups',
  SEARCH = 'Search',
  SECURITY = 'Security',
  SECURITY_OVERVIEW = 'Security_Overview',
  SECURITY_TITLE_CHARGES = 'Title_Charges',
  REGISTRATION_LAND_CHARGES_TITLE_NUMBER = 'Registration_Land_Charges_Title_Number',
  SECURITY_VALUATION = 'Security_Valuation',
  SECURITY_REPORT_REQUESTS = 'Security_Report_Requests',
  SECURITY_INSURANCE = 'Security_Insurance',
  SECURITY_DEEDS_TRACKER = 'Security_Deeds_Tracker',
  SECURITY_LETTINGS = 'Security_Lettings',
  SECURITY_AMENDMENTS_ORDERS = 'Security_Amendments_Orders',
  SECURITY_PARTIAL_RELEASES = 'Security_Partial_Releases',
  SECURITY_DEMANDS = 'Security_Demands',
  SECURITY_ABANDONMENT = 'Security_Abandonment',
  SECURITY_VOLUNTARY_SURRENDER = 'Voluntary_Surrender',
  SOF_ADVANCED = 'Sof_Advanced',
  SOURCE_OF_FUND_REDEMPTIONS = 'Sof_Redemptions',
  SUGGESTED_EXPOSURE = 'Suggested_Exposure',
  REPAYMENT_STRATEGY_EXPOSURE = 'Repayment_Strategy_Exposure',
  THIRD_PARTY = '3rdParties',
  THIRD_PARTY_CHILD = '3rdParties',
  TRANSACTIONS = 'Transactions',
  INTEREST_CHARGE_HISTORY = 'Interest_Charge_History',
  TRANSACTIONS_REVERSAL = 'Transactions_Reversal',
  ALLOCATION = 'Allocation',
  USERS = 'Users',
  VALUATIONS = 'Valuations',
  VERTICAL_LOG = 'Vertical_Log',
  BASE_RATE_CHANGES = 'Base_Rate_Changes',
  TRANSACTION_CODES = 'Transaction_Codes',
  TARIFF_OF_CHARGES = 'Tariff_Of_Charges',
  CHART_LOAN_MATURITY_DISTRIBUTION = 'Chart_Loan_Maturity_Distribution',
  CHART_COMPLAINTS = 'Chart_Complaints',
  CHART_DELINQUENCY = 'Chart_Delinquency',
  CHART_ARREARS = 'Chart_Arrears',
  CHART_VULNERABILITY = 'Chart_Vulnerability',
  CHART_TO_DO_LIST = 'Chart_To_Do_List',
  ARREARS = 'Arrears',
  FIELD_AGENT = 'Arrears_Field_Agent',
  DUE_DATE_CHANGE = 'Due_Day_Change',
  PAYMENT_REVIEW = 'Payment_Review',
  BREATHING_SPACE = 'Breathing_Space',
  REFUNDS = 'Refunds',
  INVOICE = 'Invoice',
  REDEMPTION_WRITEOFF = 'Redemption_WriteOff',
  REDEMPTION_WAIVE = 'Redemption_Waive',
  CALL_SUMMARY = 'Call_Summary',
}

export enum AppSectionsPermissionsKey {
  ACCOUNT = 'Info',
  ACCOUNT_CHANGE = 'Account_Change',
  REPAYMENT_STRATEGY = 'Repayment_Strategy',
  FLEXIBLE_FEATURE = 'Flexible_Feature',
  PAYMENT_HOLIDAY = 'Payment_Holiday',
  ACCOUNT_CHILD = 'Info',
  ARRANGEMENT_TO_PAY = 'Arrangement_To_Pay',
  COMPLAINTS = 'Complaints',
  COMPLAINTS_ADVANCED = 'Complaints_Advanced',
  COMPLAINTS_FOS_INPUT = 'Complaints_FOS_Input',
  COVENANTS = 'Covenants',
  CURRENT_EXPOSURE = 'Current_Exposure',
  CUSTOMER_CENTRIC = 'Customer_Centric',
  CUSTOMER_CENTRIC_DOCUMENTS = 'Customer_Centric_Documents',
  CUSTOMER_CENTRIC_NOTES = 'Customer_Centric_Notes',
  CUSTOMER_CENTRIC_RELATIONSHIPS = 'Customer_Centric_Relationships',
  CUSTOMER_CENTRIC_DASHBOARD = 'Customer_Centric_Relationships',
  CUSTOMER_CENTRIC_VULNERABILITY = 'Vulnerability',
  CUSTOMER_CENTRIC_KYC = 'Entity_KYC',
  TRANSACTION = 'Transaction_Bulk_Upload_Screen',
  TRANSACTION_IMPORT_FILES = 'Import_Transaction_Files',
  CUSTOMERS = 'Customers',
  CUSTOMERS_KYC = 'Customers_KYC',
  CUSTOMERS_GDPR = 'Customers_GDPR',
  CUSTOMERS_INSOLVENCY = 'Customers_Insolvency',
  CUSTOMERS_FINANCIAL_CRIME = 'Customers_Financial_Crime',
  CUSTOMERS_OVERVIEW = 'Customers_Overview',
  CUSTOMERS_PARTICIPANTS = 'Customers_Participants',
  CUSTOMERS_RELATED_PARTIES = 'Customers_Related_Parties',
  CUSTOMERS_VULNERABILITY = 'Customers_Vulnerability',
  DD_MANAGEMENT = 'Dd_Management',
  LITIGATION = 'Litigation',
  NON_STANDARD_COSTS = 'Non_Standard_Costs',
  RECEIVERSHIP = 'Receivership',
  ARREARS = 'Arrears',
  FIELD_AGENT = 'Arrears_Field_Agent',
  DOCUMENTS = 'Notes_Documents',
  CALLS = 'Notes_Calls',
  SUSPICIOUS = 'Notes_Suspicious',
  ENTITIES = 'Entities',
  ENTITIES_OVERVIEW = 'Entities_Overview',
  EXECUTE_FULL_REDEMPTIONS = 'Execute_Redemption_Quote',
  EXECUTE_OVERPAYMENT = 'Execute_Redemption_Quote',
  FULL_REDEMPTIONS = 'Redemption_Screen_Access',
  OVERPAYMENT = 'Redemption_Screen_Access',
  GROUP_CONFIG = 'Group_Config',
  GROUP_EXPOSURE = 'GroupExposure',
  GROUPEXPOSURE_COVENANTS = 'GroupExposure_Covenants',
  GROUPEXPOSURE_KYC = 'GroupExposure_KYC',
  INCOME_EXPENDITURE = 'Income_Expenditure',
  INDIVIDUALCUSTOMERS = 'IndividualCustomers',
  NOTES = 'Notes',
  PARTIAL_REDEMPTIONS = 'Redemption_Screen_Access',
  ACCOUNT_INFORMATION = 'Account_Information',
  PRODUCT_SWITCH = 'Product_Switch',
  READ_FULL_REDEMPTIONS = 'Read_Redemption_Quote',
  RELATEDPARTIES = 'RelatedParties',
  REMINDERS_ASSIGN = 'Reminders_Assign',
  REPLICATE_FULL_REDEMPTIONS = 'Replicate_Redemption_Quote',
  REPLICATE_OVERPAYMENT = 'Replicate_Redemption_Quote',
  RESTRUCTURE = 'Restructure',
  GOVERNMENT_SCHEME = 'Government_Scheme',
  SEARCH = 'Search',
  SEARCH_CHILD = 'Search',
  SECURITY = 'Security',
  SECURITY_OVERVIEW = 'Security_Overview',
  SECURITY_TITLE_CHARGES = 'Title_Charges',
  REGISTRATION_LAND_CHARGES_TITLE_NUMBER = 'Registration_Land_Charges_Title_Number',
  SECURITY_VALUATION = 'Security_Valuation',
  SECURITY_REPORT_REQUESTS = 'Security_Report_Requests',
  SECURITY_INSURANCE = 'Security_Insurance',
  SECURITY_DEEDS_TRACKER = 'Security_Deeds_Tracker',
  SECURITY_LETTINGS = 'Security_Lettings',
  SECURITY_AMENDMENTS_ORDERS = 'Security_Amendments_Orders',
  SECURITY_PARTIAL_RELEASES = 'Security_Partial_Releases',
  SECURITY_DEMANDS = 'Security_Demands',
  SECURITY_ABANDONMENT = 'Security_Abandonment',
  SECURITY_VOLUNTARY_SURRENDER = 'Voluntary_Surrender',
  SOF_ADVANCED = 'Sof_Advanced',
  SOURCE_OF_FUND_REDEMPTIONS = 'Sof_Redemptions',
  SUGGESTED_EXPOSURE = 'Suggested_Exposure',
  REPAYMENT_STRATEGY_EXPOSURE = 'Repayment_Strategy_Exposure',
  SUPER_USER = 'Roles_Permissions',
  SUPER_USER_ROLES_AND_PERMISSIONS = 'Roles_Permissions',
  SUPER_USER_SCOPES_GROUPS = 'Scopes_Groups',
  SUPER_USER_USERS = 'Users',
  THIRD_PARTY = '3rdParties',
  THIRD_PARTY_CHILD = '3rdParties',
  TRANSACTIONS = 'Transactions',
  INTEREST_CHARGE_HISTORY = 'Interest_Charge_History',
  TRANSACTIONS_REVERSAL = 'Transactions_Reversal',
  ALLOCATION = 'Allocation',
  VALUATIONS = 'Valuations',
  VERTICAL_LOG = 'Vertical_Log',
  BASE_RATE_CHANGES = 'Base_Rate_Changes',
  TRANSACTION_CODES = 'Transaction_Codes',
  TARIFF_OF_CHARGES = 'Tariff_Of_Charges',
  CHART_LOAN_MATURITY_DISTRIBUTION = 'Chart_Loan_Maturity_Distribution',
  CHART_COMPLAINTS = 'Chart_Complaints',
  CHART_DELINQUENCY = 'Chart_Delinquency',
  CHART_ARREARS = 'Chart_Arrears',
  CHART_VULNERABILITY = 'Chart_Vulnerability',
  CHART_TO_DO_LIST = 'Chart_To_Do_List',
  DUE_DATE_CHANGE = 'Due_Day_Change',
  PAYMENT_REVIEW = 'Payment_Review',
  BREATHING_SPACE = 'Breathing_Space',
  REFUNDS = 'Refunds',
  INVOICE = 'Invoice',
  REDEMPTION_WRITEOFF = 'Redemption_WriteOff',
  REDEMPTION_WAIVE = 'Redemption_Waive',
  CALL_SUMMARY = 'Call_Summary',
}
