import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  L10n,
  setCulture,
  setCurrencyCode,
  loadCldr,
} from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { EventFireService } from '@shared/services/event-fire.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { gregorianEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/ca-gregorian';
import { currenciesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/currencies';
import { datesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/dates';
import { numbersEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/numbers';
import { timeZonesEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/timeZones';
import { unitsEN } from 'src/assets/i18n/syncfusion/CLDR/en-GB/units';
import { enLanguage } from 'src/assets/i18n/syncfusion/en-GB';
import { esLanguage } from 'src/assets/i18n/syncfusion/es';
import { unitsES } from 'src/assets/i18n/syncfusion/CLDR/es/units';
import { currenciesES } from 'src/assets/i18n/syncfusion/CLDR/es/currencies';
import { datesES } from 'src/assets/i18n/syncfusion/CLDR/es/dates';
import { numbersES } from 'src/assets/i18n/syncfusion/CLDR/es/numbers';
import { timeZonesES } from 'src/assets/i18n/syncfusion/CLDR/es/timeZones';
import { gregorianES } from 'src/assets/i18n/syncfusion/CLDR/es/ca-gregorian';
import {
  DynamicModelApiService,
  GeocodingService,
  VerticalLogApiService,
  AddressValidationApiService,
} from '@horizon/dm-core';
import {
  DocumentsApiService,
  UsersService,
  WorkloadApiService,
} from '@horizon/core';
import { AppSections } from '@shared/enums/permissions.enums';
import { environment } from 'src/environments/environment';
import { globalSettings } from 'src/global-settings';
import { UrlBuilder } from 'http-url-builder';
import { ClickListenerService } from '@shared/services/click-listener.service';

loadCldr(gregorianEN, currenciesEN, datesEN, numbersEN, timeZonesEN, unitsEN);
setCulture('en-GB');
setCurrencyCode('GBP');
L10n.load(enLanguage);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  isLoading$ = new BehaviorSubject<boolean>(false);
  public loadingImg: string = '../assets/img/' + globalSettings.loadingImage;
  private clickSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    private eventFireService: EventFireService,
    private usersService: UsersService,
    private changeDetector: ChangeDetectorRef,
    private workloadsService: WorkloadApiService,
    private documentsService: DocumentsApiService,
    private dynamicModelApiService: DynamicModelApiService,
    private geocodingService: GeocodingService,
    private verticalLogApiService: VerticalLogApiService,
    private addressValidationApiService: AddressValidationApiService,
    private clickListenerService: ClickListenerService,
    private renderer: Renderer2
  ) {
    this.clickSubscription = this.clickListenerService
      .getClickObservable()
      .subscribe((event: MouseEvent) => {
        this.handleGlobalClick(event);
      });

    this.workloadsService.setAppEnvironment({
      workLoadApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.workLoadApi)
        .addPath('/')
        .build(),
      blobStorage: environment.blobStorage,
    });

    this.documentsService.setAppEnvironment({
      documentsApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.documentsApi)
        .addPath('/')
        .build(),
    });

    this.dynamicModelApiService.setAppEnvironment({
      dynamicModelApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.dynamicModelApi)
        .addPath('/')
        .build(),
    });

    this.geocodingService.setAppEnvironment(environment);

    this.verticalLogApiService.setAppEnvironment({
      dynamicModelApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.dynamicModelApi)
        .addPath('/')
        .build(),
    });

    this.addressValidationApiService.setAppEnvironment({
      addressValidationApiBaseUrl: new UrlBuilder(environment.apim.baseUrl)
        .addPath(environment.apim.apiSlugs.addressValidationApi)
        .build(),
    });

    this.usersService.setAppSectionsToCheckForPermissions(AppSections);

    this.isLoading$.next(true);
    translate.addLangs(['en', 'es']);

    //DEFAULT LANGUAGE
    this.translate.use('en');
    setCulture('en-GB');
    setCurrencyCode('GBP');
    L10n.load(enLanguage);

    this.eventFireService.getCurrentLanguage().subscribe({
      next: (lan) => {
        this.translate.use(lan);
        if (lan === 'es') {
          loadCldr(
            gregorianES,
            currenciesES,
            datesES,
            numbersES,
            timeZonesES,
            unitsES
          );
          setCulture('es');
          setCurrencyCode('EUR');
          L10n.load(esLanguage);
        } else if (lan === 'en') {
          loadCldr(
            gregorianEN,
            currenciesEN,
            datesEN,
            numbersEN,
            timeZonesEN,
            unitsEN
          );
          setCulture('en-GB');
          setCurrencyCode('GBP');
          L10n.load(enLanguage);
        }
      },
    });

    // this.usersService.getCurrentUserPermissionsReady().subscribe((ready) => {
    //   if (ready) this.isLoading$.next(false);
    // });

    //TODO: Remove when the permissions have to be checked
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 2000);
  }

  ngOnInit(): void {
    const loadingImageInIndex = document.getElementById('app-loader-in-index');

    if (loadingImageInIndex) {
      loadingImageInIndex.style.display = 'none';
    }
  }

  handleGlobalClick(event: MouseEvent) {
    event.stopPropagation();
    const clickedElement = event.target as HTMLElement;
    if (
      clickedElement instanceof HTMLInputElement ||
      clickedElement instanceof HTMLTextAreaElement
    ) {
      this.renderer.selectRootElement(clickedElement).focus();
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
