<!-- GENESYS TELEPHONY -->
<!-- ---------------------------------------------------------------------------------- -->

<!-- #region GENESYS TELEPHONY IFRAME -->
<div class="genesys-telephony-container">
  <iframe
    [ngClass]="
      !showGenesysTelephony ? 'genesys-telephony-container-hidden' : ''
    "
    class="genesys-telephony-container_iframe"
    id="softphone"
    allow="camera *; microphone *"
    [src]="genesysTelephonyUrl"
  ></iframe>
</div>
<!-- #endregion -->

<!-- #region GENESYS TELEPHONY INCOMING CALL MODAL -->
<div class="genesys-telephony-incoming-call-container">
  <div
    [ngClass]="
      !showIncomingCallNotification
        ? 'genesys-telephony-incoming-call-container-hidden'
        : ''
    "
    class="genesys-telephony-incoming-call-container_content"
  >
    <div class="genesys-telephony-incoming-call-container_content-first-row">
      <div>
        <horizon-icon [customSVG]="phoneIcon"></horizon-icon>
      </div>
      <div>
        <div
          class="genesys-telephony-incoming-call-container_content-first-row_incoming-call copy-bold dark-blue"
        >
          {{ "actions.incoming_call" | translate }}
        </div>
      </div>
    </div>
    <div class="genesys-telephony-incoming-call-container_content-second-row">
      <div
        class="action-form-btn-wrapper genesys-telephony-incoming-call-container_content-second-row_buttons"
      >
        <button
          ejs-button
          class="action_button save genesys-telephony-incoming-call-container_content-second-row_buttons-size"
          [disabled]="false"
          (click)="answerCall()"
        >
          {{ "actions.answer" | translate }}
        </button>
        <button
          ejs-button
          class="action_button exit genesys-telephony-incoming-call-container_content-second-row_buttons-size"
          [disabled]="false"
          (click)="declineCall()"
        >
          {{ "actions.decline" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
