import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, Observable, of, Subject, filter, takeUntil } from 'rxjs';
import { globalSettings } from 'src/global-settings';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportData } from '../../models';
import { UsersService } from '@horizon/core';
import { ToastService } from '@horizon/core-notifications';

@Component({
  selector: 'call-log-report-view',
  templateUrl: './call-log-report-view.component.html',
  styleUrls: ['./call-log-report-view.component.scss'],
})
export class CallLogReportViewComponent implements OnInit, OnDestroy {
  @Input() isEditMode: boolean = false;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveReportData: EventEmitter<ReportData> =
    new EventEmitter<ReportData>();

  //#region Private Properties
  private callId: string;
  private categories: Array<any>;
  private conversationDetails: {
    conversationId: string;
    participantId: string;
    communicationId: string;
  };
  private readonly destroy$ = new Subject<void>();
  private generalFormData: any;
  private identificationFieldsPassed: number = 0;
  private identificationFieldsVerified: Array<{
    name: string;
    value: 'Valid' | 'Invalid';
    from: 'Id' | 'Verification';
  }> = [];
  private idVPromptsData: any;
  private isCallSummaryEdited: boolean = false;
  private isCallSummaryPopulated: boolean = false;
  private passWordValidated: boolean = false;
  private reportData: ReportData;
  private verificationFieldsPassed: number = 0;
  private verificationFieldsVerified: Array<{
    name: string;
    value: 'Valid' | 'Invalid';
    from: 'Id' | 'Verification';
  }> = [];
  private verificationSpecialFieldToVerify: string = 'Password';
  private wrapUpData: {
    state: string;
    code: string;
    name: string;
    provisional: boolean;
  } = {
    state: 'disconnected',
    code: '',
    name: '',
    provisional: false,
  };
  //#endregion

  //#region Public Properties
  public areFormsInitialized: boolean = false;
  public callClosureCode: string;
  public callClosureCodeSelected: boolean = false;
  public callClosureOptions = [];
  public callClosureOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public callSummary: string;
  public canUserEditCallSummary: boolean = false;
  public categorySelected: boolean = false;
  public categoryOptions = [];
  public categoryOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public currentLanguage: string;
  public generalForm: FormGroup;
  public generalFormIcon: { [key: string]: any } = {
    direction: '',
    id_v: '',
    vulnerability: 'None',
  };
  public readonly globalSettings: any = globalSettings;
  public identificationForm: FormGroup;
  public identificationFormGroupFields: Array<any>;
  public isAgentCalling: boolean = false;
  public linkedAccounts: Array<any> = [];
  public prompts: Array<any>;
  public successfulContactOptions = [
    { caption: 'Yes', key: 'Yes' },
    { caption: 'No', key: 'No' },
  ];
  public successfulContactOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public telephoneNote: string;
  public verificationForm: FormGroup;
  public verificationFormGroupFields: Array<any>;
  //#endregion

  constructor(
    private readonly apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly callLogService: HorizonGenesysCallLogService,
    private translate: TranslateService,
    private readonly toastService: ToastService,
    private readonly userService: UsersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLanguage = this.translate.currentLang;
    this.callId = this.callLogService.getLocalStorageData('GenesysData').callID;
    await this.getFormData();
    await this.getReportData();
    if (!this.isEditMode) {
      this.canUserEditCallSummary = this.userService.checkUserPermission(
        'CALL_SUMMARY',
        'UPDATE'
      );
    } else {
      this.getConversationDetails();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  //#region Public Methods
  /**
   * Updates the call closure code based on the selected event data
   *
   * @param {any} event - The event containing the selected call closure data
   */
  public callClosureChanged(event: any): void {
    this.callClosureCodeSelected = true;
    this.reportData.callLog.callClosure = event.itemData.key;
    this.wrapUpData.code = event.itemData.key;
    this.wrapUpData.name = event.itemData.caption;
  }

  /**
   * Updates category selection and refreshes call closure options
   *
   * @param {any} event - The event containing the selected category data
   */
  public categoryChanged(event: any): void {
    this.reportData.category = event.itemData.key;
    this.categorySelected = true;
    this.callClosureCodeSelected = false;
    this.callClosureOptions = this.populateCallClosureOptions(
      event.itemData.key
    );
  }

  /**
   * Determines if the cancel button should be disabled
   *
   * @returns {boolean} True if the button should be disabled
   */
  public disableCancelButton(): boolean {
    return (
      !this.isEditMode ||
      (!this.isEditMode &&
        this.canUserEditCallSummary &&
        !this.isCallSummaryEdited)
    );
  }

  /**
   * Determines if the save button should be disabled
   *
   * @returns {boolean} True if the button should be disabled
   */
  public disableSaveButton(): boolean {
    return (
      ((!this.isEditMode || !this.isCallSummaryPopulated) &&
        !this.canUserEditCallSummary) ||
      (!this.isEditMode &&
        this.canUserEditCallSummary &&
        !this.isCallSummaryEdited)
    );
  }

  /**
   * Retrieves a form control from a form group
   *
   * @param {any} formGroup - The form group containing the control
   * @param {string} fieldName - The name of the control to retrieve
   * @returns {AbstractControl} The requested form control
   */
  public getControl(formGroup: any, fieldName: string): AbstractControl {
    if (formGroup && formGroup instanceof FormGroup) {
      return formGroup.get(`${fieldName}`);
    }
    return new FormControl('');
  }

  /**
   * Handles populating call summary data and updates UI accordingly
   */
  public populateCallSummary(): void {
    this.callLogService
      .setCallClosureCode(this.conversationDetails, this.wrapUpData)
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (resp) => {},
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Genesys Integration with Call Log'
          );
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.found_data',
            type: 'success',
          });
          this.isCallSummaryPopulated = true;
          this.cdr.detectChanges();
        },
      });
  }

  /**
   * Saves the current report data
   */
  public saveData(): void {
    if (this.canUserEditCallSummary && !this.isEditMode) {
      this.reportData.callLog.id = this.generalFormData.rows[0].id.value;
    }
    this.saveReportData.emit(this.reportData);
  }

  /**
   * Toggles the selection state of a linked account
   *
   * @param {{ accountId: number; accountRef: string }} accountId - The account information
   */
  public selectLinkedAccount(accountId: {
    accountId: number;
    accountRef: string;
  }): void {
    const clickedLinkedAccount =
      this.reportData.callLog.additionalFields.LinkedAccounts.findIndex(
        (linkedAccount) => linkedAccount.AccountId === accountId.accountId
      );
    if (clickedLinkedAccount !== -1)
      this.reportData.callLog.additionalFields.LinkedAccounts[
        clickedLinkedAccount
      ].Selected =
        !this.reportData.callLog.additionalFields.LinkedAccounts[
          clickedLinkedAccount
        ].Selected;
  }

  /**
   * Updates the successful contact status based on the event data
   *
   * @param {any} event - The event containing the selected contact status
   */
  public successfulContactChanged(event: any): void {
    this.reportData.callLog.successfulContact = event.itemData.key === 'Yes';
  }

  /**
   * The function `switchChanged` updates the `Result` property of a specific
   * content item in `reportData.callLog.Content` based on the event and origin
   * form, and performs form verifications accordingly.
   * @param {any} event - The `event` parameter in the `switchChanged` function
   * seems to be an object that contains information about the event that triggered
   * the switch change. It likely includes properties like `name` and `value` that
   * are used to identify the specific switch that was changed and the new value of
   * the switch.
   * @param {string} originForm - The `originForm` parameter in the `switchChanged`
   * function represents the form type from which the event originated. It is used
   * to determine which specific form verification function to call based on the
   * type of form that triggered the event.
   */
  public switchChanged(event: any, originForm: string): void {
    const contentToModify = this.reportData.callLog.content.findIndex(
      (content) =>
        content.type.toLowerCase() === originForm.toLowerCase() &&
        content.textId === event.name
    );
    if (this.isEditMode)
      this.reportData.callLog.content[contentToModify].result = event.value;

    const newFieldToVerify = {
      name: event.name,
      value: event.value,
      from: originForm,
    };
    this.idAndVFormsVerification(newFieldToVerify);

    if (
      this.identificationFieldsVerified.length >= 2 &&
      (this.verificationFieldsVerified.length >= 2 || this.passWordValidated)
    ) {
      this.generalForm.controls['id_v'].setValue(
        this.identificationFieldsPassed >= 2 &&
          (this.verificationFieldsPassed >= 2 || this.passWordValidated)
          ? 'Passed'
          : 'Failed'
      );
      this.reportData.callLog.idVStatus =
        this.generalForm.controls['id_v'].value;
    }
    this.setGeneralFormId_VIcon();
  }

  /**
   * Updates form data based on text area changes
   *
   * @param {string} field - The field being updated
   * @param {any} event - The change event data
   */
  public textAreaChange(field: string, event: any): void {
    if (this.isEditMode) {
      if (field === 'TelephoneNote') this.reportData.telephoneNote = event;
      if (field === 'CallSummaryEdited')
        this.reportData.callLog.callSummaryEdited = event;
    } else if (field === 'CallSummaryEdited') {
      this.reportData.callLog.callSummaryEdited = event;
      if (this.generalFormData.rows[0].callSummaryEdited.value !== event)
        this.isCallSummaryEdited = true;
    }
  }

  //#endregion
  //#region Private Methods
  /**
   * Creates a form group based on filtered rows data
   *
   * @param {any[]} rows - Array of form field data
   * @param {string} type - Type of form to build
   * @returns {FormGroup} The constructed form group
   */
  private buildForm(rows: any[], type: string): FormGroup {
    const group: { [key: string]: FormControl } = {};

    rows
      .filter(
        (row) =>
          row.type.value.toLowerCase() === type.toLowerCase() &&
          (row.answer?.value ?? '') !== ''
      )
      .forEach((row) => {
        const validators = this.getValidators(row.fieldType.extraData);
        group[row.textId.value] = new FormControl(
          row.fieldType.value === 'Date'
            ? new Date(row.answer.value)
            : row.answer.value || '',
          validators
        );
      });

    return this.formBuilder.group(group);
  }

  /**
   * Creates dynamic forms for identification and verification
   *
   * @returns {Observable<void>}
   */
  private generateDynamicForm(): Observable<void> {
    return of(this.idVPromptsData['rows']).pipe(
      map((rows: any[]) => {
        this.identificationForm = this.buildForm(rows, 'Id');
        this.verificationForm = this.buildForm(rows, 'Verification');
      })
    );
  }

  /**
   * Retrieves conversation details from the service
   */
  private getConversationDetails(): void {
    this.callLogService
      .getConversationDetails(this.callId)
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.conversationDetails = {
            conversationId: res.conversationId,
            participantId: res.participants[0].participantId,
            communicationId: res.participants[0].sessions[0].sessionId,
          };
        },
        error: (err) => {
          console.log(err);
          this.apiExceptionsHandlerService.defaultErrorToasts(err, 'Genesys');
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.found_data',
            type: 'success',
          });
        },
      });
  }

  /**
   * Retrieves and processes form data
   */
  private async getFormData(): Promise<void> {
    await this.callLogService.formData$
      .pipe(
        filter((_res) => _res !== null),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          if (data?.General?.data && data?.ID_V_Prompt?.data) {
            this.generalFormData = data.General.data;
            this.idVPromptsData = data.ID_V_Prompt.data;

            this.initializeForms()
              .catch((error) =>
                this.apiExceptionsHandlerService.defaultErrorToasts(
                  error,
                  'Initialization of forms'
                )
              )
              .finally(() => {
                this.toastService.showToastNotification({
                  text: 'call_log.success.found_data',
                  type: 'success',
                });
                this.areFormsInitialized = true;
              });
          }
        },
        error: (error) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            error,
            'Call Log'
          );
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.initialized_call_log',
            type: 'success',
          });
        },
      });
  }

  /**
   * Retrieves report data from the service
   */
  private async getReportData(): Promise<void> {
    await this.callLogService.reportData$
      .pipe(
        filter((_res) => _res !== null),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.reportData = data;
        },
        error: (error) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            error,
            'Call Log'
          );
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.found_data',
            type: 'success',
          });
        },
      });
  }

  /**
   * Returns an array of validators based on field requirements
   *
   * @param {any} extraData - Additional validation configuration
   * @returns {any[]} Array of validators
   */
  private getValidators(extraData: any): any[] {
    const validators = [];
    if (extraData) {
      if (extraData.required) validators.push(Validators.required);
      if (extraData.email) validators.push(Validators.email);
    }
    return validators;
  }

  /**
   * Handles verification of identification and verification form fields
   *
   * @param {any} newFieldToVerify - Field to be verified
   */
  private idAndVFormsVerification(newFieldToVerify: any): void {
    const groupFieldToVerify =
      newFieldToVerify.from.toLowerCase() === 'id'
        ? 'identificationFieldsVerified'
        : 'verificationFieldsVerified';

    const index = this[groupFieldToVerify].findIndex(
      (field) =>
        field.name === newFieldToVerify.name &&
        field.from === newFieldToVerify.from
    );

    const fieldsPassed =
      newFieldToVerify.from.toLowerCase() === 'id'
        ? 'identificationFieldsPassed'
        : 'verificationFieldsPassed';

    if (index !== -1) {
      const pastValue = this[groupFieldToVerify][index].value;
      this[groupFieldToVerify][index].value = newFieldToVerify.value;

      if (newFieldToVerify.value === 'Valid' && pastValue !== 'Valid') {
        this[fieldsPassed] += 1;
      } else if (
        newFieldToVerify.value === 'Invalid' &&
        pastValue === 'Valid' &&
        this[fieldsPassed] > 0
      ) {
        this[fieldsPassed] -= 1;
      }
    } else {
      this[groupFieldToVerify].push(newFieldToVerify);
      if (newFieldToVerify.value === 'Valid') {
        this[fieldsPassed] += 1;
      }
    }

    this.verificationFieldsVerified.find((verifiedField) => {
      if (
        verifiedField.name.toLowerCase() ===
        this.verificationSpecialFieldToVerify.toLowerCase()
      ) {
        this.passWordValidated = verifiedField.value === 'Valid';
      }
    });
  }

  /**
   * Initializes all forms in the component
   */
  private async initializeForms(): Promise<void> {
    this.initializeGeneralForm();
    this.identificationFormGroupFields = this.mapRowToFormData(
      this.idVPromptsData.rows,
      'Id'
    );
    this.verificationFormGroupFields = this.mapRowToFormData(
      this.idVPromptsData.rows,
      'Verification'
    );
    this.prompts = this.mapRowToFormData(this.idVPromptsData.rows, 'Prompt');

    await this.generateDynamicForm()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.cdr.detectChanges(),
        error: (error) => {
          this.toastService.showToastNotification({
            text: 'call_log.errors.initialized_call_log',
            type: 'error',
          });
          console.log(error);
        },
        complete: () => {
          this.toastService.showToastNotification({
            text: 'call_log.success.initialized_call_log',
            type: 'success',
          });
        },
      });

    if (!this.isEditMode) {
      this.generalForm.disable({ onlySelf: true });
      this.identificationForm.disable({ onlySelf: true });
      this.verificationForm.disable({ onlySelf: true });
      this.telephoneNote = this.generalFormData.rows[0].callLogNote?.value;
      this.callSummary = this.generalFormData.rows[0].callSummaryEdited?.value;
    }
    this.cdr.detectChanges();
    this.areFormsInitialized = true;
  }

  /**
   * Initializes the general form with default values
   */
  private initializeGeneralForm(): void {
    this.populateCategoryOptions();
    if (this.isEditMode)
      this.isAgentCalling =
        this.callLogService.getLocalStorageData('GenesysData').callSubType ===
        'Agent';
    this.generalForm = new FormGroup({
      direction: new FormControl(
        this.isAgentCalling
          ? this.callLogService.getLocalStorageData('GenesysData').callType
          : this.generalFormData.rows[0].callType.value
      ),
      id_v: new FormControl(
        this.isEditMode
          ? 'Not Completed'
          : this.generalFormData.rows[0].idvStatus.value === 'Uncomplete'
          ? 'Not Completed'
          : this.generalFormData.rows[0].idvStatus.value
      ),
      vulnerability: new FormControl(
        this.generalFormData.rows[0].vulnerability.value
      ),
      category: new FormControl(this.generalFormData.rows[0].category.value),
      callFromTo: new FormControl(
        this.generalFormData.rows[0].callFromTo.value
      ),
      successfulContact: new FormControl(
        this.isAgentCalling
          ? ''
          : this.generalFormData.rows[0].successfulContact.value
          ? 'Yes'
          : 'No'
      ),
      accountNumber: new FormControl(
        this.generalFormData.rows[0].entityId.value.accountRef
      ),
      relationship: new FormControl(
        this.generalFormData.rows[0].relationship.value
      ),
      linkedAccount: new FormControl(
        this.generalFormData.rows[0].linkedAccounts.value
      ),
      callClosure: new FormControl(
        this.generalFormData.rows[0].callClosure?.value
      ),
    });
    this.linkedAccounts = this.generalFormData.rows[0].linkedAccounts.value;
    this.setGeneralFormIcons();
  }

  /**
   * Maps row data to form fields based on form type
   *
   * @param {any} rows - The rows data to map
   * @param {string} formType - The type of form to map to
   * @returns {Array<any>} Mapped form data
   */
  private mapRowToFormData(rows: any, formType: string): Array<any> {
    return rows.filter(
      (prompt: any) =>
        prompt.type.value.toLowerCase() === formType.toLowerCase()
    );
  }

  /**
   * Populates call closure options based on category
   *
   * @param {string} categoryName - The selected category name
   * @returns {Array<any>} Array of call closure options
   */
  private populateCallClosureOptions(categoryName: string): Array<any> {
    if (categoryName !== '' && this.categories && this.categories.length > 0) {
      const filteredCategory = this.categories.find(
        (filteredCategory) => filteredCategory.name === categoryName
      );
      return filteredCategory.children.map((category) => ({
        key: category.name,
        caption: category.displayName[this.currentLanguage.toLowerCase()],
      }));
    }
    return [];
  }

  /**
   * Populates category options from form data
   */
  private populateCategoryOptions(): void {
    this.categories = this.generalFormData.columns.find(
      (column) => column.name === 'Category'
    ).options;
    this.callClosureOptions = this.populateCallClosureOptions(
      this.generalFormData.rows[0].category.value
    );
    this.categoryOptions = this.categories.map((category) => ({
      key: category.name,
      caption: category.displayName[this.currentLanguage.toLowerCase()],
    }));
  }

  /**
   * Sets form icons based on current form values
   */
  private setGeneralFormIcons(): void {
    this.generalFormIcon.direction = {
      iconName:
        this.generalForm.controls['direction'].value === 'Outbound'
          ? 'callOut'
          : 'callIn',
      iconGroup: '',
      iconColor: globalSettings.colors.mainColor,
    };
    this.setGeneralFormId_VIcon();
    this.generalFormIcon.vulnerability = {
      iconName:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? 'tick_rounded'
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? 'warning'
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? 'cross_rounded'
          : 'warning',
      iconGroup:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? 'tooltip'
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? ''
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? ''
          : 'tooltip',
      iconColor:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? globalSettings.colors.successColor
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? globalSettings.colors.warningColor
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? globalSettings.colors.errorColor
          : globalSettings.colors.neutralDarkColor,
    };
  }

  /**
   * Sets the ID verification icon state
   */
  private setGeneralFormId_VIcon(): void {
    this.generalFormIcon.id_v = {
      iconName:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? 'warning'
          : this.generalForm.controls['id_v'].value === 'Passed'
          ? 'tick_rounded'
          : 'cross_rounded',
      iconGroup:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? 'tooltip'
          : '',
      iconColor:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? globalSettings.colors.neutralDarkColor
          : this.generalForm.controls['id_v'].value === 'Passed'
          ? globalSettings.colors.successColor
          : globalSettings.colors.errorColor,
    };
  }
  //#endregion
}
